import ApiService from "@/core/services/general.service";
import JwtService from "@/core/services/jwt.service";

// Endpoints
const resource = process.env.VUE_APP_API_BASE_GENERAL_URL + "Branch";
const branchesService = {
  get(model) {
    // return ApiService.get(resource, "");
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`${resource}/Branches`, model);
    }
  },

  getByKey(key) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(resource, key);
    }
  },

  create(data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(resource, data);
    }
  },

  update(key, data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.update(resource, key, data);
    }
  },

  delete(id) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.delete(`${resource}/${id}`, id);
    }
  },

  activate(id) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.put(`${resource}/status/${id}/Active`);
    }
  },
  deactivate(id) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.put(`${resource}/status/${id}/Inactive`);
    }
  },
};

export default branchesService;
